import Grid from "@mui/material/Grid";
import { Link, Typography } from "@mui/material";
import style from "./css/index.module.scss";
import Page from "../components/page";

function DefaultAd() {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Link
        href="https://afdian.com/item/8ee70458b8b311ecae3052540025c377"
        variant="h6"
        target="_blank"
        underline="none"
      >
        <Typography variant="body1" gutterBottom component="p">
          购买推广位
        </Typography>
      </Link>
    </div>
  );
}

export default function Ad() {
  const adList = [
    {
      link: "https://curl.qcloud.com/9AUmfTAH",
      title:
        "【腾讯推广】2核2G配置，长达一年的15人24小时不间断的小服只需60~80元！",
      subTitle: "无需新用户，只要从没买过均有一次购买资格。",
      startTime: "2022/4/10",
      endTime: "2099/1/1",
    },
    {
      link: "https://www.dkdun.cn/aff/ZRSUTDFU",
      title: "DKDUN-林枫云网络-稳定高防高频VPS",
      subTitle: "主营高频游戏云-均为独立IP",
      startTime: "2024/8/30",
      endTime: "2024/12/4",
    },
    {
      link: "https://pro.cfhost.cn/cart",
      title: "【CFHOST.CN】三网BGP 9950X 13900KF游戏云",
      subTitle: "13900KF,9950X游戏云/香港优化/美国Cera优化/加拿大OVH超防",
      startTime: "2024/8/29",
      endTime: "2025/2/7",
      qq: 424773790,
    },
    {
      link: "https://ai2019.com/",
      title: "【阿水云】全网最低价游戏云 ai2019.com",
      subTitle: "【阿水云】https://ai2019.com/",
      startTime: "2024/10/5",
      endTime: "2024/11/6",
    },
    {
      link: "https://www.wuluoyun.com/",
      title: "高防云主机【五洛云计算】大宽带服务器",
      subTitle: "高防云主机/高防物理机/大宽带服务器/服务器托管",
      startTime: "2024/10/8",
      endTime: "2024/12/8",
    },
    {
      link: "https://www.gfvps.cn/activity.html",
      title: "★数掘科技★",
      subTitle: "全球高防|CDN99元|服务器29元",
      startTime: "2024/10/25",
      endTime: "2025/1/26",
    },
    {
      link: "https://idc.daozhiyun.cn/aff/DGNMLNKW",
      title: "【道之云网络】4H4G云服务器低至28元/月 助您低价高效上云",
      subTitle: "点击上方链接获取更多优惠配置",
      startTime: "2025/2/12",
      endTime: "2025/3/12",
    },
    {
      link: "https://www.pysio.online",
      title: "熊猫的小窝",
      subTitle: "这真的只是一个窝而已",
      startTime: "2024/12/16",
      endTime: "2025/1/17",
    },
    {
      link: "https://minecraft.hi.cn",
      title: "繁星梦我的世界服务器",
      subTitle: "一个公益Folia高性能生存生电建筑服务器",
      startTime: "2024/12/18",
      endTime: "2025/1/18",
    },
    {
      link: "https://www.bing-ads.cn/?utm_source=mcsm",
      title: "搜索引擎竞价广告开户投放",
      subTitle: "必应/谷歌/百度/360/Yandex 金牌代理 全球可开",
      startTime: "2024/12/23",
      endTime: "2025/1/23",
    },
  ];

  return (
    <Page type={0} className={style.container} style={{ marginTop: "-180px" }}>
      <div
        style={{
          marginTop: "0px",
          maxWidth: "1100px",
          margin: "auto",
          textAlign: "left",
        }}
      >
        <Typography variant="h4" gutterBottom component="div">
          推广
        </Typography>
        <Grid container spacing={2} maxWidth="lg">
          {adList.map((v, index) => {
            if (new Date(v.endTime) < new Date()) {
              return null;
            }
            return (
              <Grid item md={6} key={index}>
                <div>
                  <Link href={v.link} variant="h6" target="_blank">
                    <Typography variant="body1" gutterBottom component="p">
                      {v.title}
                    </Typography>
                  </Link>
                  <Typography variant="caption" gutterBottom component="p">
                    {v.subTitle}
                  </Typography>
                </div>
              </Grid>
            );
          })}
        </Grid>
        <div style={{ margin: "36px 0px" }}>
          <DefaultAd></DefaultAd>
        </div>
      </div>
    </Page>
  );
}
