import style from "./css/index.module.scss";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import zhConsolePageImage from "../images/zh-console-page.png";
import enConsolePageImage from "../images/panel/index-en.png";
import { scrollToAnchor } from "../utils/page";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import AssistantPhotoOutlinedIcon from "@mui/icons-material/AssistantPhotoOutlined";
import AutoAwesomeMosaicOutlinedIcon from "@mui/icons-material/AutoAwesomeMosaicOutlined";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import DynamicFormOutlinedIcon from "@mui/icons-material/DynamicFormOutlined";

function englishIndexImages() {
  return (
    <div className={style.pageImg}>
      <div className=" ">
        <img src={enConsolePageImage} alt="" width="100%" />
      </div>
    </div>
  );
}

function chineseIndexImages() {
  return (
    <div className={style.pageImg}>
      <div className=" ">
        <img src={zhConsolePageImage} alt="" width="100%" />
      </div>
    </div>
  );
}

function Page() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const features = [
    {
      text: t("index.features.0"),
      icon: <AssistantPhotoOutlinedIcon fontSize="small" />,
    },
    {
      text: t("index.features.3"),
      icon: <DynamicFormOutlinedIcon fontSize="small" />,
    },
    {
      text: t("index.features.5"),
      icon: <AutoAwesomeMosaicOutlinedIcon fontSize="small" />,
    },
    {
      text: t("index.features.2"),
      icon: <DashboardCustomizeOutlinedIcon fontSize="small" />,
    },
    {
      text: t("index.features.4"),
      icon: <PersonAddAltOutlinedIcon fontSize="small" />,
    },
    {
      text: t("index.features.1"),
      icon: <ConstructionOutlinedIcon fontSize="small" />,
    },
  ];

  return (
    <div className={style.container} style={{ marginTop: "140px" }}>
      {/* <BackGround /> */}
      <Typography
        className={style.highlightInfo + " " + style.indexTitle}
        variant="h3"
        gutterBottom
        fontSize="3.6rem"
        lineHeight="4rem"
        fontWeight={800}
        component="div"
      >
        <span dangerouslySetInnerHTML={{ __html: t("title") }}></span>
      </Typography>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            margin: "auto",
            maxWidth: "920px",
            display: "flex",
            flexWrap: "wrap",
            marginTop: "30px",
            justifyContent: "center",
          }}
        >
          {features.map((v) => {
            return (
              <div
                style={{
                  width: "460px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
                className={style.descWrapper}
              >
                <div style={{ verticalAlign: "middle" }}>{v.icon}</div>
                <Typography
                  style={{ marginLeft: "4px" }}
                  className="text-child"
                  variant="subtitle1"
                  gutterBottom
                  component="div"
                  textAlign="center"
                  dangerouslySetInnerHTML={{ __html: v.text }}
                />
              </div>
            );
          })}
        </div>
      </div>

      <div style={{ marginTop: "60px" }}>
        <Stack
          spacing={2}
          direction={{ xs: "column", sm: "row" }}
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              size="large"
              variant="contained"
              color="success"
              onClick={() => scrollToAnchor("quickstart")}
            >
              {t("index.quickstart")}
            </Button>

            <a
              href="https://docs.mcsmanager.com/"
              target="_blank"
              rel="noreferrer"
            >
              <Button size="large" variant="contained">
                {t("index.doc")}
              </Button>
            </a>
          </Stack>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {language === "zh_cn" && (
              <>
                <Button
                  size="large"
                  variant="outlined"
                  onClick={() => scrollToAnchor("about")}
                >
                  {t("index.about")}
                </Button>
              </>
            )}
          </Stack>
        </Stack>
      </div>

      <div style={{ textAlign: "center", marginTop: "100px" }}>
        {language === "zh_cn" ? chineseIndexImages() : englishIndexImages()}
      </div>
    </div>
  );
}

export default Page;
